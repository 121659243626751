
var Toggle = {

    init: function() {
        /**
         * Cart - identity pick
         * Trenutno ni v uporabi
         */
        // $(document.body).on('click', '.toggle-visible', Toggle.visibility);

        /* Toggle.active bindi */
        // Uporabljeno pri FAQ-ih
        $('li.question > i, li.question > a', '.act-tggl').on('click', Toggle.faq);
        // Univerzalni sprožilec
        $('.js-act-tggl').on('click', Toggle.active);
        // Uporabljeno na straneh posameznih domen in specifikaciji certifikatov
        $(document.body).on('click', '.collapse-btn', Toggle.active);
        // Toggle.active bind za click in hover event
        $(document.body).on('click hover', '.js-act-tggl-hvr', Toggle.active);

        // Gostovanje - primerjava
        $('.collapsable > .title', '.package-list').on('click', Toggle.collapsable);
        // Collapsable cart
        $('.collapsable-control .title').on('click', function (event) {
            Toggle.collapsable(event, this, '.collapsable-item');
            Triggers.equalHeightsMultiple();
        });
    },

    faq: function(event, element) {
        event.preventDefault();
        let $targetParent = $(this).parent();

        if ($targetParent.hasClass('active')) {
            $targetParent.find('.faq-answer').slideUp(function() {
                $targetParent.removeClass('active'); // Remove active class after slideUp is complete
            });
        } else {
            $targetParent.find('.faq-answer').slideDown(function() {
                $targetParent.addClass('active'); // Add active class after slideDown is complete
            });

            // Update URL with ID if present
            let id = $targetParent.attr('id');
            if (typeof id !== 'undefined' && id.length) {
                if (history.pushState) {
                    history.pushState(null, null, '#' + id);
                }
            }
        }
    },

    /**
     * Stikalo za child elemente
     * Po defaultu toggla parent element.
     * Sprejme data-target-toggle('self/.xxx')
     */
    active: function (event, element)
    {
        var target,
            $target,
            toggleTarget,
            toggleSiblings,
            $this,
            className = 'active';

        event.preventDefault();

        if (typeof  element ===  'undefined') {
            target = this;
        } else {
            target = element;
        }
        $target = $(target);

        $this = $(this);

        if ($this.data('toggle-class')) {
            className = $this.data('toggle-class');
        }

        if ($target.data('toggle-closest-target')) {
            toggleTarget = $target.data('toggle-closest-target');
        } else {
            toggleTarget = $target.data('toggle-target');
        }

        toggleSiblings = $target.data('toggle-siblings');

        if (typeof  toggleTarget !==  'undefined') {
            if (toggleTarget === 'self') {
                target.classList.toggle(className);
            } else if ($target.data('toggle-closest-target')) {
                $this.closest(toggleTarget).toggleClass(className);
                target.classList.toggle(className);
            } else {
                $(toggleTarget).toggleClass(className);
                target.classList.toggle(className);
            }
        } else {
            var $targetParent = $target.parent(),
                id = $targetParent.attr('id');
            if (typeof toggleSiblings !== 'undefined') {
                $targetParent.siblings().removeClass(className);
            }
            $targetParent.toggleClass(className);

            if (typeof id !== 'undefined' && id.length) {
                if(history.pushState) {
                    history.pushState(null, null, '#' + id);
                }
            }


        }
        $.event.trigger('element-toggle');
    },

    /**
     * Toggla target. Target pridobi it data-target(id) oziroma iz child-prefixa
     * @param  {object} event       [description]
     * @param  {this object} element     [description]
     * @param  {string} childPrefix [description]
     * @return {void}             [description]
     */
    collapsable: function (event, element, parent)
    {
        var target;
        var toggleTarget;
        var $element;
        var childPrefix = 'collap-';
        var className = 'active';


        if (typeof  element ===  'undefined') {
            element = this;
        }
        $element = $(element);

        target = $element.data('target');
        if (target) {
            toggleTarget = $('#' + target);
        } else {
            $.each($element.parent('.collapsable')[0].classList, function(index, val) {
                if (val.substring(0, childPrefix.length) === childPrefix) {
                    toggleTarget = '.' + val + ' .title ~ div';
                }
            });
        }

        if ($element.data('toggle-class')) {
            className = $element.data('toggle-class');
        }

        $element.toggleClass(className);

        $('.collapsable-content').velocity('slideUp',
                { 'duration': 100 }
            ).closest('.collapsable-item').removeClass(className);

        if ($(toggleTarget).is(':visible') ) {
            $(toggleTarget).velocity('slideUp',
                { 'duration': 100 }
            );
        } else {
            $(toggleTarget).velocity('slideDown',
                { 'duration': 100 }
            );
        }


        if (typeof parent !== 'undefined') {
            $(toggleTarget).parent(parent).toggleClass(className);
        }
    },

    /**
     * Še ni v uporabi - pripravljeno za podračune
     */
    /*
    visibility: function (event, element)
    {
        var $element,
            $target;

        event.preventDefault();

        if (typeof  element ===  'undefined') {
            element = this;
        }
        $element = $(element);

        $target = $element.siblings('.visibility-target');

        if ($target.is(':visible')) {
            $target.velocity('slideUp',
                { 'duration': 100 }
            ).toggleClass('hidden');
        } else {
            $target.velocity('slideDown',
                { 'duration': 100 }
            ).toggleClass('hidden');
        }

        if($element.data('toggleOriginalText')) {
            $element.text($element.data('toggleOriginalText')).removeData('toggleOriginalText');
        } else if($element.data('toggleAlterText')) {

            $element.data('toggleOriginalText', $element.text()).text($element.data('toggleAlterText'));
        }
    }*/




};

