/*jshint loopfunc: true*/
/*jshint esnext: true*/
var DomainCheck = {
    tlds: TLD_LIST,
    query: null,
    url: null,
    perpage: 10,
    currentTlds: null,

    startAdvanced: function(el) {
        DomainCheck.start();
    },

    init: function() {
        Debug.log('DomainCheck.init');
        // Iskanje domene
        $(document.body).on('submit', '.js-domaincheck-form', DomainCheck.start);

        var $domainCheck = $('.js-domaincheck-form');
        if ($domainCheck.length) {
            var domain_input = $domainCheck.find('.js-domaincheck-input');
            if (domain_input.val() !== '') {
                domain_input.closest('.js-domaincheck-form').submit();
            }
        }

        if($('.js-domaincheck-input').data('fake-type')) {
            DomainCheck.fakeType($('.js-domaincheck-input'));
        }
    },

    start: function (e) {
        Debug.log('DomainCheck.start');

        $(document.body).trigger('domain-check--start');
        if(Hlp.defined(e)) {
            e.preventDefault();

        }
        var el = $('.js-domaincheck-form');

        var domainInput = el.find('.js-domaincheck-input');
        var domain = domainInput.val();
        //some basic domain validation?
        domain = DomainCheck.validate(domain);
        if(domain === false) {
            return false;
        } else {
            domainInput.val(domain);
        }

        var advanced = $('.js-domaincheck-wrapper.advanced').length > 0;
        var domainResults = $('.js-domaincheck-results');

        var domainList = $('.js-domaincheck-query');

        // Doda domeno v URI in vrne nov url
        var url = Hlp.appendParametersToUrl({'domena': domain});

        // Sprememba URLja ni delovala ali pa se zahteva check z istimi parametri
        if (url === DomainCheck.url) {
            return false;
        }

        DomainCheck.url = url;
        DomainCheck.query = domain;

        if (!advanced) {
            Debug.log('DomainCheck.start: Simple mode');
            var $checkOther = $('.check-other');
            if ($checkOther.length) {
                $checkOther.attr('href', $checkOther.attr('href').replace('#domain#', domain));
            }
        }
        domainResults.parent().addClass('search-active');
        setTimeout(function() {
            domainResults.velocity('slideDown', {
                duration: 400,
                complete: function() {
                    $(document.body).trigger('domain-check--show');
                }
            });
        }, 500);


        $('.js-domaincheck-item').each(function(i, e) {
            $(e).hide();
        });

        var item;
        var domainRoot = domain.split(/\.(.+)?/)[0];
        var thetld = domain.split(/\.(.+)?/)[1];

        var prefer_tld = el.data('prefer-tld');
        if(prefer_tld !== '') {
            thetld = prefer_tld;
        }

        if(advanced) {
            $('.js-domaincheck-filters').hide().removeClass('hidden').fadeIn();
            DomainCheck.currentTlds = tld_list;
            if(Hlp.defined(thetld)) {
                DomainCheck.currentTlds.unshift(thetld);
            }
        } else {

            DomainCheck.currentTlds = DomainCheck.tlds;
            if(Hlp.defined(DomainCheck.original_tlds)) {
                DomainCheck.original_tlds = DomainCheck.tlds.slice(0);
            }
            //odstrani iskani TLD in ga dodaj na vrh array-a
            if(Hlp.defined(thetld)) {
                var index = DomainCheck.currentTlds.indexOf(thetld);
                if (index > -1) {
                    DomainCheck.currentTlds.splice(index, 1);
                }
                DomainCheck.currentTlds.unshift(thetld);
            }

            if(el.data('prefer-tld') !== '') {
                DomainCheck.currentTlds.unshift(el.data('prefer-tld'));
            }

        }

        var i = 0;
        var domainResultsCounter = 0;
        DomainCheck.currentTlds.forEach(function(tld) {
            i = i+1;
            var id = 'tld-' + tld;
            var domainName = domainRoot + '.' + tld;
            var $domainCheck = $($('#domain-check-template-holder').html());
            item = $domainCheck;
            item.removeProp('id');
            $('#' + id).empty().remove();
            item.prop('id', id);
            item.css('display', 'flex');

            var domainTag = item.find('.domain-tag-premium');

            if (domainTag.parents('.checkout-content').length && (tld === thetld || el.data('prefer-tld') !== '' && tld === el.data('prefer-tld'))) {
                item.addClass('stand-out');
                domainTag.addClass('tag-primary-standout-70');
            } else {
                domainTag.addClass('tag-primary-70');
            }

            $('.placeholder-domain', item).text(domainName);

            domainList.append(item);

            if(i > DomainCheck.perpage) {
                item.addClass('hidden').data('dn', domainName);
            } else {
                DomainCheck.check(item, domainName, tld === thetld);
                domainResultsCounter++;
            }
        });

        if(DomainCheck.currentTlds.length > DomainCheck.perpage) {
            $(window).on('scroll', function() {
                if(Hlp.inViewport( $('.domain-query > .js-domaincheck-item:visible').eq(-1)) ) {
                    if(!$('.js-domaincheck-wrapper .loading').length) {
                        $('.domain-query > .js-domaincheck-item.hidden:lt(3)').removeClass('hidden').each(function(i, e){
                            DomainCheck.check($(e), $(e).data('dn'));
                            domainResultsCounter++;
                        });
                    }
                }
            });
        }

        Tracking.trackSearchPiwik({'term': domain, 'category': 'domain', 'resultsCount': domainResultsCounter});
    },
    removeFromCurrentTlds : function (tld) {
        if (typeof tld === 'undefined') {
            tld = this.tlds[0];
        }
        var tldIndex = this.currentTlds.indexOf(tld);
        if (tldIndex !== -1) {
            this.currentTlds.splice(tldIndex, 1);
            return true;
        }
        return false;
     },
    check: function(item, domainName, searchTld = false) {
        Debug.log('DomainCheck.check');

        var button = {
            'valid' : 'btn-green',
            'special' : 'btn-blue'
        };

        $.ajax({
            cache: false,
            url: Hlp.getApi() + '/domains/available?domain=' + domainName + (searchTld ? '&search=1' : ''),
            success: function(data) {
                if (searchTld) {
                    var trackingParams = {'term': domainName, 'category': 'domain'};

                    if (data.eventId) {
                        trackingParams.eventId = data.eventId;
                    }

                    Tracking.trackSearch(trackingParams);
                }

                var tld = null;

                if(data.hasOwnProperty('domain')) {
                    tld = data.domain.replace(data.domainRoot + '.', '');
                }

                var $toCartItem = $('.js-to-cart', item),
                    $oldPrice = $('.old-price', item),
                    plh = $('.placeholder-status[data-status=' + data.status +  ']'),
                    $domainPromo = $('.domain-promo', item);



                if(data.hasOwnProperty('item')) {
                    $toCartItem.attr('data-item-id', data.item.id).attr('data-domain', data.domain).attr('data-type', 'domain');
                    if (Cart.isInCart(data.item.id, data.domain)) {
                        Cart.addAlreadyInCart($toCartItem);
                    }
                }

                $('.placeholder-domain', item).text(data.domain);
                $('.placeholder-status', item).html(plh.html()).removeAttr('data-status');
                $('.domain-status i', item).attr('class', plh.data('icon'));

                if ( /* item.closest('.advanced').length && */ data.country && data.country.iso2) {
                    var $flagContainer = $('.flag', item);
                    $flagContainer.hide().removeClass('hidden').show();
                    $flagContainer.addClass('flag-' + data.country.iso2.toLowerCase());
                    var countryName = data.country.slo_name ? data.country.slo_name : data.country.short_name;
                    $flagContainer.attr('title', countryName);
                }

                item.removeClass('domain-loading');
                var lowestPriceString = '';

                if(data.hasOwnProperty('item')) {
                    if (data.lowestPrice.priceType == 'promo') {
                        // Activate popover - so qTip gets latest data (promo.until)
                        $domainPromo.hide().removeClass('hidden').show();
                        if(data.lowestPrice.discount.until) {
                            var promoDate = new Date(data.lowestPrice.discount.until.public.date);
                            $domainPromo.find('.promo-until').text(promoDate.getDate() + '.' + (promoDate.getMonth() + 1) + '.' + promoDate.getFullYear());
                        }
                        $domainPromo.children('#domain-promo').attr('id', 'domain-promo-' + data.item.id);
                        $domainPromo.children('.icon-discount-right').data('qtip-content', '#domain-promo-' + data.item.id).attr('data-qtip-content', '#domain-promo-' + data.item.id).addClass('popover');
                    } else if (data.lowestPrice.priceType == 'tier') {
                        // Activate popover - so qTip gets latest data (promo.until)
                        $domainPromo.hide().removeClass('hidden').show();
                        $domainPromo.find('.cnt').html('Cena te domene vsebuje količinski popust.');
                        //$domainPromo.find('.promo-until').text(data.data.lowestPrice.discount.until.public.date);
                        $domainPromo.children('#domain-promo').attr('id', 'domain-promo-' + data.item.id);
                        $domainPromo.children('.icon-discount-right').data('qtip-content', '#domain-promo-' + data.item.id).attr('data-qtip-content', '#domain-promo-' + data.item.id).addClass('popover');
                    } else {
                        // Remove useless DOM
                        $domainPromo.remove();
                    }

                    if (typeof (data.lowPrice.priceVat) != 'undefined') {
                        $oldPrice.html(Hlp.slonum(data.lowPrice.priceVat) + ' &euro;');
                    }
                    if (typeof (data.lowPrice.priceVat) == 'undefined' || data.lowPrice.priceVat <= data.lowestPrice.priceVat) {
                        //$oldPrice.css({'visibility': 'hidden'});
                        $oldPrice.siblings('br').remove();
                        $oldPrice.remove();
                    }

                    lowestPriceString = Hlp.slonum(data.lowestPrice.priceVat);
                }

                var $parent = $('.domain-status', item).children('div:first');
                var domainTag;
                var word;
                // update status class
                switch (data.status) {
                case 'domain-not-supported':
                    item.addClass('domain-fail');
                    if(Hlp.defined(DomainCheck.original_tlds)) {
                        if(DomainCheck.original_tlds.indexOf(tld) == -1) {
                            if (DomainCheck.currentTlds.indexOf(tld) > -1) {
                                DomainCheck.currentTlds.splice(DomainCheck.currentTlds.indexOf(tld), 1);
                            }
                        }
                    }
                    DomainCheck.removeFromCurrentTlds(tld);
                    break;

                case 'domain-registered':
                    item.addClass('domain-info');
                    $('.js-domaincheck-actions, .domain-price', item).show();
                    $('.js-domaincheck-actions .'+ button.valid, item).addClass(button.special).removeClass(button.valid);
                    $('.new-price', item).html(lowestPriceString + ' &euro;').show();

                    $toCartItem.data('option', 'transfer_in')
                        .attr('data-option', 'transfer_in');
                    break;
                case 'domain-registered-with-us':
                    item.addClass('domain-info');
                    $('.js-domaincheck-actions, .domain-price', item).show();
                    $('.js-domaincheck-actions .'+button.valid, item).addClass(button.special).removeClass(button.valid);
                    $('.new-price', item).html(lowestPriceString + ' &euro;').show();
                    $toCartItem.data('option', 'registered')
                        .attr('data-option', 'registered');
                    break;
                case 'domain-registered-with-us-expired':
                    item.addClass('domain-fail');
                    DomainCheck.removeFromCurrentTlds(tld);
                    break;
                case 'domain-reserved':
                    item.addClass('domain-info');
                    DomainCheck.removeFromCurrentTlds(tld);
                    break;
                case 'domain-available':
                    item.addClass('domain-ok');

                    if(data.promoted) {
                        item.addClass('domain-promoted');
                        item.find('.corner-ribbon').hide().removeClass('hidden').fadeIn();
                    }

                    $('.js-domaincheck-actions, .domain-price', item).show();
                    $('.new-price', item).html(lowestPriceString + ' &euro;').show();
                    $toCartItem.data('option', 'new')
                        .attr('data-option', 'new');
                    $('.domain-status', item).attr('title', plh.text());
                    $('.placeholder-domain', item).attr('title', plh.text());
                    //IDN domain
                    if(data.domain != data.domainIDN) {
                        $('.placeholder-status', item).html(data.domainIDN + '<i data-qtip-ajax="/tooltips/idn" class="icon-info-circle-alt popover idn-warning"></i>').fadeIn();
                    }

                    if (data.item) {
                        if (data.item.localPresenceAddon) {
                            $toCartItem.data('addon-' + data.item.localPresenceAddon.id, '1')
                                .attr('data-addon-' + data.item.localPresenceAddon.id, '1');
                        }

                        if (data.item.localPresenceRequired && data.item.termsTrustee) {
                            $parent.append($('<small class="placeholder-status" style="display: block;">Potrebna lokalna prisotnost<i data-qtip-ajax="' + encodeURI('/tooltips/local-presence-required?content=' + data.item.termsTrustee) + '" class="icon-info-circle-warning-alt popover idn-warning"></i></small>')).fadeIn();
                        }

                        if (data.item.minRegisterYears > 1) {
                            word = data.item.maxRegisterYears === data.item.minRegisterYears ? 'le' : 'vsaj';
                            $parent.append($('<small class="placeholder-status" style="display: block;">To domeno lahko registrirate ' + word + ' za ' + DomainCheck.getYears(data.item.minRegisterYears) + '</small>')).fadeIn();
                        }
                    }

                    break;

                case 'domain-available-with-terms':
                    item.addClass('domain-ok');

                    if(data.promoted) {
                        item.addClass('domain-promoted');
                        item.find('.corner-ribbon').hide().removeClass('hidden').fadeIn();
                    }

                    item.addClass('domain-terms');
                    $('.js-domaincheck-actions, .domain-price', item).show();
                    $('.new-price', item).html(lowestPriceString + ' &euro;').show();
                    $toCartItem.data('option', 'new')
                        .attr('data-option', 'new');
                    $('.placeholder-domain', item).attr('title', data.title);
                    var $placeholderStatus = $('.placeholder-status', item),
                        htmlPlaceholderStatus = $placeholderStatus.html();
                    if (htmlPlaceholderStatus) {
                        $placeholderStatus.html(htmlPlaceholderStatus.replace('##tld##', tld.toUpperCase()));
                    }

                    var $aMore = $('a.more', item),
                        aMoreHref = $aMore.attr('href');
                    if (aMoreHref) {
                        $aMore.attr('href', aMoreHref.replace('##tld##', tld));
                    }

                    if (data.item) {
                        if (data.item.localPresenceAddon) {
                            $toCartItem.data('addon-' + data.item.localPresenceAddon.id, '1')
                                .attr('data-addon-' + data.item.localPresenceAddon.id, '1');
                        }

                        if (data.item.localPresenceRequired && data.item.termsTrustee) {
                            $parent.append($('<small class="placeholder-status" style="display: block;">Potrebna lokalna prisotnost<i data-qtip-ajax="' + encodeURI('/tooltips/local-presence-required?content=' + data.item.termsTrustee) + '" class="icon-info-circle-warning-alt popover idn-warning"></i></small>')).fadeIn();
                        }

                        if (data.item.minRegisterYears > 1) {
                            word = data.item.maxRegisterYears === data.item.minRegisterYears ? 'le' : 'vsaj';
                            $parent.append($('<small class="placeholder-status" style="display: block;">To domeno lahko registrirate ' + word + ' za ' + DomainCheck.getYears(data.item.minRegisterYears) + '</small>')).fadeIn();
                        }
                    }

                    break;

                case 'domain-premium':
                    item.addClass('domain-ok');
                    $('.js-domaincheck-actions, .domain-price', item).show();
                    $('.new-price', item).html(lowestPriceString + ' &euro;').show();

                    domainTag = $('.domain-tag-premium', item);
                    domainTag.show();
                    break;

                case 'domain-invalid' :
                    item.addClass('domain-fail');
                    item.addClass('hide-me');
                    if(Hlp.defined(DomainCheck.original_tlds)) {
                        if(DomainCheck.original_tlds.indexOf(tld) == -1) {
                            if (DomainCheck.currentTlds.indexOf(tld) > -1) {
                                DomainCheck.currentTlds.splice(DomainCheck.currentTlds.indexOf(tld), 1);
                            }
                        }
                    }
                    DomainCheck.removeFromCurrentTlds(tld);
                    break;

                case 'domain-invalid-length' :
                    item.addClass('domain-fail');
                    item.addClass('hide-me');
                    if(Hlp.defined(DomainCheck.original_tlds)) {
                        if(DomainCheck.original_tlds.indexOf(tld) == -1) {
                            if (DomainCheck.currentTlds.indexOf(tld) > -1) {
                                DomainCheck.currentTlds.splice(DomainCheck.currentTlds.indexOf(tld), 1);
                            }
                        }
                    }
                    $placeholderStatus = $('.placeholder-status', item);
                    $placeholderStatus.html($placeholderStatus.html().replace('##length##', data.options.min_letters + ' - ' + data.options.max_letters));
                    DomainCheck.removeFromCurrentTlds(tld);
                    break;

                case 'general-exception':
                    item.addClass('domain-fail');
                    DomainCheck.removeFromCurrentTlds(tld);
                    break;

                case 'domain-premium-registered':
                    item.addClass('domain-info');
                    $('.js-domaincheck-actions, .domain-price', item).show();
                    $('.js-domaincheck-actions .'+button.valid, item).addClass(button.special).removeClass(button.valid);
                    $('.new-price', item).html(lowestPriceString + ' &euro;').show();
                    $toCartItem.data('option', 'transfer_in')
                        .attr('data-option', 'transfer_in');
                    domainTag = $('.domain-tag-premium', item);
                    domainTag.show();
                    break;

                case 'domain-premium-registered-with-us':
                    item.addClass('domain-info');
                    $('.js-domaincheck-actions, .domain-price', item).show();
                    $('.js-domaincheck-actions .'+button.valid, item).addClass(button.special).removeClass(button.valid);
                    $('.new-price', item).html(lowestPriceString + ' &euro;').show();
                    $toCartItem.data('option', 'registered')
                        .attr('data-option', 'registered');
                    domainTag = $('.domain-tag-premium', item);
                    domainTag.show();
                    break;

                case 'domain-idn-not-supported':
                    item.addClass('domain-fail');
                    DomainCheck.removeFromCurrentTlds(tld);
                    break;
                }


                item.removeClass('domain-loading');

                return true;
            },
            error : function(data) {
                var $placeholder;
                item.removeClass('domain-loading');
                item.addClass('domain-fail');

                // Exception response
                switch (data.status) {
                case 429:
                    $placeholder = $('.placeholder-status[data-status=general-rate-limit]');
                    break;
                default:
                    $placeholder = $('.placeholder-status[data-status=general-exception]');
                    DomainCheck.removeFromCurrentTlds();
                }

                $('.placeholder-status', item).html(
                    $placeholder.html()
                );
                $('.domain-status i', item).attr('class', $placeholder.data('icon'));

                return false;
            },
        }).always(function( data ) {
            $(document.body).trigger('domain-check--checked');
        });
    },

    mainDomain: function (domain, $hostingItemId) {
        Debug.log('DomainCheck.mainDomain');

        if (domain === '') {
            return false;
        }

        return $.ajax({
            url: Hlp.getApi() + '/domains/available?domain=' + domain + ($hostingItemId ? '&item_id=' + $hostingItemId : '')
        });

    },


    validate: function (domain, replace_www) {
        Debug.log('DomainCheck.validate');

        if(domain === '') return false;

        if(typeof(replace_www) == 'undefined') {
            replace_www = true;
        }

        domain = domain.toLowerCase().trim();

        if(replace_www) {
            domain = domain.replace('www.', '');
        }

        var notAllowed = ['https://', 'http://', /(.*)@/i, '//', /\/.*/, '*', '?', '/', ',', '..'];

        notAllowed.forEach(function(element) {
            domain = domain.replace(element, '');
        });

        if(domain.slice(-1) == '.')domain = domain.slice(0, -1);

        var parts = domain.split(/\.(.+)?/);
        var root = parts[0];
        if(root.slice(-1) == ' ')root = root.slice(0, -1);
        root = root.split(' ').join('-');

        if(parts.length == 1) {
            domain = root;
        } else {
            var tld = parts[1];
            tld = tld.split(' ').join('');
            domain = root + '.' + tld;
        }
        return domain;
    },

    getYears: function (years) {
        var remainder = years % 100;

        switch (remainder) {
            case 1:
                return years + ' leto';
            case 2:
                return years + ' leti';
            case 3:
            case 4:
                return years + ' leta';
            default:
                return years + ' let';
        }
    },

    fakeType: function (input) {
        // Add something to given element placeholder
        function addToPlaceholder(toAdd, el) {
            el.attr('placeholder', el.attr('placeholder') + toAdd);
            // Delay between symbols "typing"
            return new Promise(resolve => setTimeout(resolve, 100));
        }

        // Cleare placeholder attribute in given element
        function clearPlaceholder(el) {
            el.attr("placeholder", "");
        }

        // Print one phrase
        function printPhrase(phrase, el) {
            return new Promise(resolve => {
                // Clear placeholder before typing next phrase
                clearPlaceholder(el);
                let letters = phrase.split('');
                // For each letter in phrase
                letters.reduce(
                    (promise, letter, index) => promise.then(_ => {
                        // Resolve promise when all letters are typed
                        if (index === letters.length - 1) {
                            // Delay before start next phrase "typing"
                            setTimeout(resolve, 1500);
                        }
                        return addToPlaceholder(letter, el);
                    }),
                    Promise.resolve()
                );
            });
        }

        // Print given phrases to element
        function printPhrases(phrases, el) {
            // For each phrase
            // wait for phrase to be typed
            // before start typing next
            phrases.reduce(
                (promise, phrase) => promise.then(_ => printPhrase(phrase, el)),
                Promise.resolve()
            );
        }

        // Start typing
        function run() {
            printPhrases(input.data('fake-type'), input);
        }

        run();
    }
};
