/*jslint browser: true */

/**
* Klass namenjen trigger-jem, ki se uporabljajo večkrat (init, tabs, ajax)
*/
var Triggers = {
    equalHeightsMultiple: function () {
        // Univerzalna uporaba alignov
        Resizer.equalHeightsMultiple('.brd-bx', '.align');
        Resizer.equalHeightsMultiple('.brd-bx', '.align-1');
        Resizer.equalHeightsMultiple('.brd-bx', '.bx-ttl.align');
        Resizer.equalHeightsMultiple('.brd-bx', '.bx-cntnt.align-2');
        Resizer.equalHeightsMultiple('.brd-bx', '.bx-ttl.align-2');
        Resizer.equalHeightsMultiple('.brd-bx', '.bx-cntnt.align-3');
        Resizer.equalHeightsMultiple('.brd-bx', '.bx-ttl.align-3');

        // Vertikalna poravnava elementa
        Resizer.alignCenterHeight('.auto-height');
        //Resizer.equalHeights('#pick-domain-cat .bx-cntnt', 3);
        Resizer.alignCenterHeight('.domain-status > i:not(.icon-check-circle)');
        // Height glede na parent element
        Resizer.maxHeight('.max-height');

    },
    selectric: function (parent) {
        // Custom select box
        var selector = '.selectric, .selectric-sm, .selectric-xs, .selectric-md, .selectric-md2, .selectric-lg, .selectric-fw';
        var $elements = $(selector);
        if(typeof(parent) !== 'undefined') {
            $elements = $(selector, parent);
        }
        $elements.selectric({
            disableOnMobile: false,
            nativeOnMobile: false,
            optionsItemBuilder: function (itemData, element) {
                element = itemData.element;

                var el = {
                        tag: 'span',
                        text: '',
                        addClass: '',
                        data: element.data(),
                        newData: '',
                        child: {
                            addClass: ''
                        }
                    },
                    newElement = '',
                    attributes = null,
                    $element;

                el.child.addClass = el.data.class ? el.data.class : '';
                if (element.length) {
                    if (element.get(0).classList) {
                        $.each(element.get(0).classList, function (index, val) {
                            el.addClass += ' ' + val;
                        });
                    }
                }
                $element = $(element);

                // Če ima select class 'copy-attr' skopiraj vse atribute
                if ($element.closest('select').hasClass('copy-attr')) {
                    attributes = $(element).prop('attributes');
                    if (attributes) {
                        attributes = $.map(attributes, function(item) {
                            return item.name + '="' + item.nodeValue + '"';
                        }).join(' ');
                    }
                }
                if (el.data.text) {
                    el.text = el.data.text;
                    el.child.addClass = el.child.addClass ? el.child.addClass : 'additional';
                    if (el.data.textWeight) {
                        el.tag = el.data.textWeight;
                    }
                } else if (el.data.icon) {
                    el.tag = 'i';
                    el.child.addClass += el.child.addClass ? el.child.addClass + ' ' + el.data.icon : el.data.icon;
                } else if (attributes) {
                    return '<span ' + attributes + '>' + itemData.text + '</span>';
                } else {
                    return '<span class="text ' + el.child.addClass + el.addClass + '">' + itemData.text + '</span>';
                }
                if (typeof el.data.position == 'undefined' || el.data.position != 'before') {
                    newElement = '<span class="text' + el.addClass + '">' + itemData.text + '</span><' + el.tag + ' class="' + el.child.addClass + ' after">' + el.text + '</' + el.tag + '>';
                } else {
                    newElement = '<' + el.tag + ' class="' + el.child.addClass + ' before">' + el.text + '</' + el.tag + '><span class="text' + el.addClass + '">' + itemData.text + '</span>';
                }
                return newElement;
            },

            labelBuilder: function (currItem) {
                // Label - prikaz se uporablja v primeru odprtega dropdowna - pri 'Sortiraj po:'
                var response = currItem.text;
                var selectText = currItem.element.parent('select').data('label-text');
                if (selectText) {
                    response = '<span class="normal">' + currItem.text + '</span><span class="hovered">' + selectText + '</span>';
                }
                return response;
            },

            //inheritOriginalWidth: true,
            onChange: function (select, selectric) {
                var $select = $(select),
                    $selectElement = $(select).get(0),
                    selected_opt = $selectElement[$selectElement.selectedIndex],
                    $selected_opt = $(selected_opt);

                $select.trigger('change');

                $selected_opt.trigger('click');

                $select.find('option').attr('selected', false);
                $selected_opt.attr('selected', 'selected');

                // V primeru, da ima option nastavljen modal trigger, ga sproži
                if (selected_opt.className == 'modal-inline') {
                    $selected_opt.trigger('click');
                }
                $select.val($selected_opt.val());
                $select.attr('value', $selected_opt.val());
            }

        });
    },

    inputHandlers: function (container) {
        if (typeof container === 'undefined') {
            container = document.body;
        }

        Tooltip.init(container);
        Triggers.selectric(container);
        CoinPayments.init();

        // Vse forme se spravljajo v LocalStorage
        /*$('form').sisyphus({
            excludeFields:
                // Multiple jquery selectors don't workjs-show-payment
                $('input[type="password"], .js-simpletab input, .js-nosisyphus input, .hidden, input[type=hidden], .search-field, .selectric-md, .selectric-sm, .selectric-js-conditional-display, .js-conditional-display, .selectric-js-value-change, .hosted-form input'),
            customKeySuffix: '',
            locationBased: false,
            timeout: 1
        });*/

        // Tabs - delay ob menjavi je obvezen
        var mytabs = $('.tab-container').tabbedContent({
            links: $('ul.flat-tabs li a'), // you can also pass a jquery/zepto object containing the links
            errorSelector: '.error-message',
            speed: false, // speed of the show effect. Set to null or false to disable
            onSwitch: function (hash, api) {
                $(hash).removeClass('hidden');
                $.event.trigger('tab-switch');
            },
            currentClass: 'active', // current selected tab class (is set to link's parent)
            tabErrorClass: 'has-error', // class to be added to tabs containing errorSelector (is set to link's parent)
            loop: false // if set to true will loop between tabs when using the next() and prev() api methods
        }).data('api');

        $(document.body).on('click', 'ul.tabs > li > a, ul.flat-tabs > li > a:not(.look-only a)', function (e) {
            e.preventDefault();
            if (typeof mytabs !== 'undefined') {
                mytabs.switch($(this).parent().index());
            }
        });

        // Uporabljeno na blogu / podpori
        $('.fancy-content').tabbedContent({
            //links: tabcontent.prev().find('a').length ? tabcontent.prev().find('a') : '.tabs-nav a', // you can also pass a jquery/zepto object containing the links
            errorSelector: '.error-message',
            speed: false, // speed of the show effect. Set to null or false to disable
            onSwitch: function (hash, tab) {
                window.setTimeout(Triggers.equalHeightsMultiple, 100);
            },
            onInit: function (tab) {
                window.setTimeout(Triggers.equalHeightsMultiple, 100); // Brez tega včasih equalHeights ne deluje na tabih
            },
            currentClass: 'active', // current selected tab class (is set to link's parent)
            tabErrorClass: 'has-error', // class to be added to tabs containing errorSelector (is set to link's parent)
            loop: false, // if set to true will loop between tabs when using the next() and prev() api methods
        }).data('api');

        // Uporabljeno na blogu / podpori
        $('.tabs-content').tabbedContent({
            //links: tabcontent.prev().find('a').length ? tabcontent.prev().find('a') : '.tabs-nav a', // you can also pass a jquery/zepto object containing the links
            errorSelector: '.error-message',
            speed: false, // speed of the show effect. Set to null or false to disable
            onSwitch: function (hash, tab) {
                window.setTimeout(Triggers.equalHeightsMultiple, 100);
            },
            onInit: function (tab) {
                window.setTimeout(Triggers.equalHeightsMultiple, 100); // Brez tega včasih equalHeights ne deluje na tabih
            },
            currentClass: 'active', // current selected tab class (is set to link's parent)
            tabErrorClass: 'has-error', // class to be added to tabs containing errorSelector (is set to link's parent)
            loop: false, // if set to true will loop between tabs when using the next() and prev() api methods
            history: false, // set to false to disable HTML5 history

        }).data('api');
    },


    modalOpen: function (modal) {
        /*var callee = modal.st.el;
        var content = modal.contentContainer;*/
        $(document.body).trigger('modal--open');
    },
    modalClose: function (modal) {
        //console.log(modal);
    },
    modalAjaxLoaded: function (modal) {
        /*var callee = modal.st.el;
        var content = modal.contentContainer;*/
        setTimeout(function () {
            $('.loading-compare .loading-bar').barslide();
        }, 500);
    }
};


var debugMode = false;

// Moneta - listen for message from API/moneta
function listenMessage(message) {
    if (message.origin.indexOf('moneta') !== -1) {
        if (typeof message.data.purchaseStatus !== 'undefined') {
            if (message.data.purchaseStatus == 'potrjeno') {
                location.reload();
            }
        }
    }
}
if (window.addEventListener) {
    window.addEventListener('message', listenMessage, false);
} else {
    window.attachEvent('onmessage', listenMessage);
}
// eof Moneta

// jQuery ready
$(function () {
    if (typeof Modernizr != 'undefined') {
        for (var key in Modernizr) {
            // skip loop if the property is from prototype
            if (!Modernizr.hasOwnProperty(key)) continue;

            if (!Modernizr[key]) {
                //Tracking.track({ eventCategory: 'Browser', eventAction: 'Old', eventLabel: navigator.userAgent});
                var browserHtml = '<div class="browser-alert text-center" role="alert">' +
                    '<p>Uporabljate <strong>zastarel</strong> brskalnik.' +
                    ' Priporočamo, da zaradi boljše uporabniške izkušnje in <strong>varnostnih razlogov</strong> ' +
                    '<a href="http://outdatedbrowser.com/sl/" ref="noopener external" target="_blank" title="Nadgradnja brskalnika">nadgradite vaš brskalnik</a>.' +
                    '</p></div> ';
                $(document.body).prepend(browserHtml);
                break;
            }
        }
    }

    /**
     * Zamenjan $.animate z Velocityem
     * Odstranjeno zaradi nekompatibilnosti.
     * - fadeIn in fadeOut ni kompitabilen. Primer je 4. korak košarice
     */
    //jQuery.fn.animate = jQuery.fn.velocity;

    // Default AJAX nastavitve. DataType zna delati probleme s plugini.
    $.support.cors = true;
    var ajax_data = {},
        access_token = null;

    var tempAccessToken = Customer.token();
    if(tempAccessToken) {
        access_token = tempAccessToken;
        if(Customer.id()) {
            ajax_data.customer_id = Customer.id();
            if (typeof Raven != 'undefined') {
                Raven.setUserContext({
                    id: Customer.id()
                });
            }
        }
    }
    if (typeof Raven != 'undefined') {
        Raven.setExtraContext({
            session_in: SESSION_ID
        });
    }

    $.ajaxSetup({
        crossDomain: true,
        data: ajax_data,
        type: 'GET',
        dataType: 'JSON',
        beforeSend: function (request) {
            request.setRequestHeader('Accept-Language', LANG);
            request.setRequestHeader('X-SessionID', SESSION_ID);
            request.setRequestHeader('Authorization', 'Bearer '+ access_token);
        },
        // In case of 500 error, report it to Sentry - remove in case of duplicated errors
        error : function(jqXHR, textStatus, errorThrown) {
            if (jqXHR.status === 500 && SENTRY_ENABLED) {
                if (typeof Raven != 'undefined') {
                    Raven.captureMessage(
                        errorThrown || jqXHR.textStatus,
                        {
                            extra: {
                                type: this.type,
                                url: this.url,
                                data: this.data,
                                status: jqXHR.status,
                                error: errorThrown || jqXHR.statusText,
                                response: jqXHR.responseText.substring(0, 100)
                            }
                        }
                    );
                }
            }
        }
    });

    Resizer.init();


    $('.content').on('click', '.modal-ajax', function (event) {
        event.preventDefault();
    });

    Ajax.init();



    // MINI CART //
    MiniCart.init();
    Toggle.init();
    Customer.init();


    // Kontakt
    $('#contact-form button').on('click', Contact.submit);
    $(document.body).on('click', '#service-contact-form .submit-button', Contact.submit);

    // Delovno mesto
    $(document.body).on('click', '#job-form .submit-button', Job.submit);

    $(document.body).on('click', '#review-form .submit-button', Review.submit);



    // Sticky top navigacija
    StickyNav.init();

    if ($('.top-header').data('sticky') === 0) {
        StickyNav.permanentActive = false;
    }


    // navigacija
    $(document.body).on('click', '.fixed-menu .has-children .mobile-submenu-toggle', function (event) {
        // Prevent default link behavior and stop the event from bubbling up
        event.preventDefault();
        event.stopPropagation();
        const $parentLi = $(this).closest('li');
        $parentLi.siblings().removeClass('active');
        $parentLi.toggleClass('active');
    });

    $(document.body).on('click', '.fixed-menu .has-children .no-link', function (event) {
        // Prevent default link behavior and stop the event from bubbling up
        const $parentLi = $(this).closest('li');
        $parentLi.siblings().removeClass('active');
        $parentLi.toggleClass('active');
    });


    // DomainCheck related triggers
    $(document.body).on('domain-check--start', function() {
        $('#domain-showbox').fadeOut();

        if (Hlp.defined(StickyNav.active)) {
            // ugasni sticky
            StickyNav.active = false;
        }

        //povsod razen na naročilnici
        if(!$('.checkout').length) {
            $('.js-domaincheck').velocity('scroll', {
                duration: 500,
                easing: false,
                complete: function() {
                    StickyNav.active = true;
                }
            });
        }

        // Skrij puščico
        $('.domaincheck-presell').fadeOut();

    });

    $(document.body).on('domain-check--checked', function() {
        Tooltip.init('.domain-query');
    });

    $(document.body).on('cart--loading', function() {
        var navigationOffsetTop = $('.js-topnavigation').offset().top;
        var isDistance = ($(window).scrollTop() > navigationOffsetTop);
        // If stickyNav isn't visisble & viewport is bellow normal navigation
        if(!$('#main-header.scrolled').length && isDistance) {
            StickyNav.show();
        }
    });

    $(document.body).on('click', '.icon-close', '.domain-showbox, .hosting-showbox', function () {
        $(this).closest('.domain-showbox, .hosting-showbox').hide();
    });

    $(document.body).on('input-handlers', function(event, selector) {
        Triggers.inputHandlers(selector);
    });

    /* Checkout - dropdown trigger */
    $('.checkout').on('click', '.dropdown-trigger', function (event) {
        event.preventDefault();
        Toggle.active(event, this);
    });

    /* Dropdown delay */
    var hoverTimeout;
    $('.dropdown-menu').hover(function () {
        clearTimeout(hoverTimeout);
    }, function () {
        var self = $(this);
        hoverTimeout = setTimeout(function () {
            self.removeClass('active');
        }, 500);
    });

    /* Ob naročilu skoči gor */
    if ($('#order-top').length) {
        if (Resizer.isDesktop) {
            $(document.body).scrollTo(160);
        } else {
            // Zaradi fixed menija je potrebnih 50px manj odmika
            $(document.body).scrollTo(110);
        }
    }
    /* Na naročilnici fokusiraj input */
    $('.checkout-content .domain-check input').focus();
    $('.focus-me').focus();


    /* Copy text */
    $(document.body).on('mouseenter', '.js-copytext', function() {
        $(this).attr('title', 'Klikni in označi!');
        $(this).css({
            cursor: 'crosshair'
        });
    }).on('click', '.js-copytext', function() {
        Utility.selecttext($(this));
    });

    Hlp.init();

    /* Pokliči preostale trigger-je */
    Triggers.inputHandlers();

    /* Modals, lightbox, share buttons */
    Triggers.equalHeightsMultiple();

    AdditionalTriggers.init();
    Wordpress.init();
    Checkout.init();
    Item.init();
    DiscountCode.init();
    Comparison.init();
    Menu.init();
    Tracking.init();
    DomainCheck.init();
    PackageCarousel.init();


    $('#stars').rating('create', {
        onClick : function (e,d) {
            var rate = $(this).find('input').val();
            var tr = $('#review-form .text-rating');
            var text = tr.data('rate' + rate);
            tr.find('strong').text(text);
        }
    });



    /* custom events */
    $(document.body).on('click', '.faq-list .question', function() { $.event.trigger('faq-toggle'); });
    $(document.body).on('click', '.kv-collapse .collapse-btn', function() { $.event.trigger('domain-data-toggle'); });
    $(document.body).on('faq-toggle tab-switch domain-check--show domain-data-toggle element-toggle', function(e) {
        //Resizer.stickyRecalc();
        Tooltip.init();
    });


    Cart.init();
    Utility.init();


    /*if (Resizer.isDesktop) {
        $('.sticky-cart').stick_in_parent({
            parent: '.checkout-side'
        });
    } else {
        $('.sticky-cart').trigger('sticky_kit:detach');
    }*/


    $('.show-on-load').removeClass('show-on-load');
    Sliders.init();
});
