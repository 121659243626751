
//hosting landing
$(document).ready(function() {
    if($('.bg-gostovanje').length) {
        $('.benefits li').eq(0).delay(1000).fadeOut().fadeIn(200);
        $('.benefits li').eq(1).delay(2000).fadeOut().fadeIn(200);
        $('.benefits li').eq(2).delay(3000).fadeOut().fadeIn(200);
        $('.benefits li').eq(3).delay(4000).fadeOut().fadeIn(200);
    }
});

//vps landing
$(document).ready(function() {
    if($('.bg-vps').length) {
        $('.bg-vps .advantages-list ul li').eq(0).delay(1000).fadeOut().fadeIn(200);
        $('.bg-vps .advantages-list ul li').eq(1).delay(2000).fadeOut().fadeIn(200);
        $('.bg-vps .advantages-list ul li').eq(2).delay(3000).fadeOut().fadeIn(200);
        $('.bg-vps .advantages-list ul li').eq(3).delay(4000).fadeOut().fadeIn(200);
        $('.bg-vps .advantages-list ul li').eq(4).delay(5000).fadeOut().fadeIn(200);
    }
});

// Turbo hosting
$(document).ready(function(){
    if($('.shooting_star').length) {
        var starElements = $(".shooting_star");
        for (var countStar = 1; countStar <= starElements.length; countStar++) {
            var delayStar = Math.floor((Math.random() * 9999) + 1);
            var top = Math.floor((Math.random() * 650) + 1);
            var left = Math.floor((Math.random() * 300) + 1);
            $(".shooting_star:nth-child(" + countStar + ")").css({
                //"top": "calc(100% - " + top + "px - 200px)",
                "top": top,
                "left": "calc(0% - " + left + "px)",
                "animation-delay": delayStar + "ms"
            });
            $(".shooting_star:nth-child(" + countStar + ")::before").css({
                "animation-delay": delayStar + "ms",
                "-webkit-animation-delay": delayStar + "ms"
            });
            $(".shooting_star:nth-child(" + countStar + ")::after").css({
                "animation-delay": delayStar + "ms",
                "-webkit-animation-delay": delayStar + "ms"
            });
        }
    }
    if($('.server polygon').length) {
        var ledLights = $(".server polygon");
        for(var countLights=1; countLights<=ledLights.length; countLights++){
            var delayLights=Math.floor((Math.random() * 500) + 1);
            $(".server polygon:nth-child(" + countLights + ")").css({
                "animation-delay": delayLights + "ms"
            });
        }
    }
});

// Reseller hosting
$(document).ready(function(){
	var advantage1 = $("#nvme");
	var advantage2 = $("#safe");
	var advantage3 = $("#cpanel");
	var advantage4 = $("#mariadb");
	var advantage5 = $("#litespeed");
	setTimeout(
		function()
		{
			advantage1.css("opacity", "1");
		}, 450);
	setTimeout(
		function()
		{
			advantage2.css("opacity", "1");
		}, 150);
	setTimeout(
		function()
		{
			advantage3.css("opacity", "1");
		}, 550);
	setTimeout(
		function()
		{
			advantage4.css("opacity", "1");
		}, 850);
	setTimeout(
		function()
		{
			advantage5.css("opacity", "1");
		}, 350);

	setTimeout(
		function()
		{
			$(".kv-list-title").css("opacity","1");
		}, 1000
	);

	// list animation
	setTimeout(
		function()
		{
			$(".kv-reseller-list li:first-child").css("opacity","1");
		}, 1600
	);
	setTimeout(
		function()
		{
			$(".kv-reseller-list li:nth-child(2)").css("opacity","1");
		}, 2100
	);
	setTimeout(
		function()
		{
			$(".kv-reseller-list li:nth-child(3)").css("opacity","1");
		}, 2600
	);
	setTimeout(
		function()
		{
			$(".kv-reseller-list li:last-child").css("opacity","1");
		}, 3100
	);

});

// Dedicated servers
$(document).ready(function(){
	var ledLights = $("#workingLights path");
	$(".buttonPlay").on("click",function(){
		$("#puscica").fadeOut();
		$(".gumbTop").css("transform", "translateY(10px)");
		setTimeout(function(){
			$(".gumbTop").css("transform", "translateY(0px)");
		}, 100);
		setTimeout(function(){
			$("#power-on").fadeIn();
		}, 200);
		setTimeout(function(){
			$(".bg-dedicated").addClass("play");
			$(".buttonPlay").fadeOut();
			$("#andyStart").fadeOut();
			$("#andyEnd").fadeIn();
			for(var countLights=1; countLights<=ledLights.length; countLights++){
				var delayLights=Math.floor((Math.random() * 500) + 1);
				var durationLights=Math.floor((Math.random() * 3)+1);
				$("#workingLights path:nth-child(" + countLights + ")").css({
					"animation-delay": delayLights + "ms"
				});
				$("#workingLights path:nth-child(" + countLights + ")").css({
					"animation-duration": durationLights + "s"
				});
			}
		}, 200);
		var countList=1;
		setTimeout(function(){
			showList();
		}, 1000);

		function showList(){
			setTimeout(function(){
				$('.advantages-list ul li:nth-child(' + countList +')').fadeIn();
				countList++;
				if(countList<=$(".advantages-list ul li").length){
					showList();
				}
			}, 600);
		}

	});
});

$(document).ready(function () {
    if($('#dataCanvasUnencrypted').length) {
        // Get both canvas elements
        const canvasUnencrypted = $("#dataCanvasUnencrypted")[0];
        const ctxUnencrypted = canvasUnencrypted.getContext("2d");

        const canvasEncrypted = $("#dataCanvasEncrypted")[0];
        const ctxEncrypted = canvasEncrypted.getContext("2d");

        // Extract words from the hidden div
        const wordList = $("#word-list").text().split(",").map(word => $.trim(word));

        const wordsUnencrypted = [];
        const wordsEncrypted = [];

        const MAX_WORDS = 12;  // Reduce clutter
        const WORD_INTERVAL = 900; // Slower spawn rate for smooth effect

        // Fixed set of colors
        const fixedColors = ["#ffffff", "#000000", "#ffff00"];

        // Function to generate random encrypted-like data
        function generateRandomData(length) {
            const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
            return Array.from({length}, () => chars[Math.floor(Math.random() * chars.length)]).join("");
        }

        // Check for vertical mode based on screen width
        let verticalMode = $(window).width() < 768;

        // Helper function to measure text width using an offscreen canvas
        function measureTextWidth(text, font) {
            const offscreenCanvas = document.createElement("canvas");
            const offscreenCtx = offscreenCanvas.getContext("2d");
            offscreenCtx.font = font;
            return offscreenCtx.measureText(text).width;
        }

        function createWord(isEncrypted = false) {
            const text = isEncrypted
                ? generateRandomData(Math.floor(Math.random() * 8) + 8)
                : wordList[Math.floor(Math.random() * wordList.length)];
            let fontSize = Math.floor(Math.random() * 10) + 14;
            const bold = Math.random() < 0.4;
            const font = (bold ? "bold " : "") + fontSize + "px monospace";
            const color = isEncrypted
                ? "#000000"
                : fixedColors[Math.floor(Math.random() * fixedColors.length)];

            if (verticalMode) {
                // Measure the text width to avoid it starting too far right.
                const textWidth = measureTextWidth(text, font);
                const maxX = canvasUnencrypted.width - textWidth;
                // Ensure we don't use a negative value if the text is wider than the canvas.
                const xPosition = maxX > 0 ? Math.random() * maxX : 0;
                const yPosition = -Math.random() * 100 - 50;
                fontSize -= 2;
                return {
                    text,
                    x: xPosition,
                    y: yPosition,
                    speed: Math.random() + 1,
                    fontSize,
                    font,
                    color
                };
            } else {
                // Horizontal mode: words start off-screen to the left
                const yPosition = Math.random() * (canvasUnencrypted.height - fontSize) + fontSize;
                return {
                    text,
                    x: -Math.random() * 100 - 50,
                    y: yPosition,
                    speed: Math.random() * 1.5 + 1.5,
                    fontSize,
                    font,
                    color
                };
            }
        }

        function animate(ctx, words, canvas) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            for (let i = words.length - 1; i >= 0; i--) {
                let word = words[i];
                if (verticalMode) {
                    word.y += word.speed;  // Move down
                } else {
                    word.x += word.speed;  // Move right
                }
                ctx.font = word.font;
                ctx.fillStyle = word.color;
                ctx.fillText(word.text, word.x, word.y);

                // Remove words once they move completely off-screen
                if (verticalMode) {
                    if (word.y > canvas.height) words.splice(i, 1);
                } else {
                    if (word.x > canvas.width) words.splice(i, 1);
                }
            }

            requestAnimationFrame(() => animate(ctx, words, canvas));
        }

        function startAnimation() {
            animate(ctxUnencrypted, wordsUnencrypted, canvasUnencrypted);
            animate(ctxEncrypted, wordsEncrypted, canvasEncrypted);
        }

        // Start spawning words at an interval
        setInterval(() => {
            if (wordsUnencrypted.length < MAX_WORDS) wordsUnencrypted.push(createWord(false));
            if (wordsEncrypted.length < MAX_WORDS) wordsEncrypted.push(createWord(true));
        }, WORD_INTERVAL);

        startAnimation(); // **Start the infinite loop**
    }
});
