/* For the sake of readability */
/* triggers with closures... */

var AdditionalTriggers = {

    init: function (parent) {

        if (typeof parent === 'undefined') {
            parent = document.body;
        }

        $('.modal, .modal-iframe, .modal-ajax, .modal-inline, #modal-trigger, .image-holder').on('mfpBeforeOpen', function(e) {
            StickyNav.hide();
        });

        // Modal / Showbox
        $('.modal', parent).magnificPopup({
            type: 'inline',
            showCloseBtn: false,
            /*mainClass: function (element) {
                return "avc";
            },      */
            callbacks: {
                beforeOpen: function (item) {
                    this.wrap.removeAttr('tabindex');
                    var elementData = $(this.st.el).data();
                    // add class if exists in data-mfp-main-class
                    if (elementData.mfpMainClass) {
                        this.st.mainClass = elementData.mfpMainClass;
                    }
                },
                open: function () {
                    var elementData = $(this.st.el).data();
                    $(elementData.mfpSrc).parent().height($(elementData.mfpSrc).height());
                    $(window).trigger('resize');
                },
            },
            ajax: {
                settings: {
                    dataType: 'html'
                }
            }
        });
        // Modal / Iframe
        $('.modal-iframe', parent).magnificPopup({
            type: 'iframe',
            iframe: {
                markup: '<section id="mdl" class="mdl">' +
                            '<div class="mdl-header"><div class="mdl-title "><h4 class="mfp-title"></h4><i class="icon-close mfp-close"></i>' +
                                '</div>' +
                            '</div>' +
                            '<div class="mdl-content">' +
                                '<iframe class="mfp-iframe" width="100%" height="600px" frameborder="0" ></iframe>'+
                            '</div>' +
                        '</section>',
                srcAction: 'iframe_src',
            },
            showCloseBtn: false,
            /*mainClass: function (element) {
                return "avc";
            },      */
            callbacks: {
                markupParse: function(template, values, item) {
                    if (item.el.data('mfp-class')) {
                        template.addClass(item.el.data('mfp-class'));
                    }
                    values.title = item.el.attr('title');
                },
            }
        });
        // Ajax modal
        $('.modal-ajax', parent).magnificPopup({
            type: 'ajax',
            showCloseBtn: false,
            tLoading: 'Nalagam ...',
            preloader: false,
            fixedBgPos: true,
            fixedContentPos: false,
            mainClass: function (element) {
                var elementData = $($.magnificPopup.instance.items[0]).data();
                return elementData.mfpMainClass ? elementData.mfpMainClass : 'mdl-md';
            },
            callbacks: {
                beforeOpen: function () {
                    this.wrap.removeAttr('tabindex');
                },
                ajaxContentAdded: function (a, b, c) {
                    Triggers.inputHandlers(this.container);
                    AdditionalTriggers.init(this.container);
                },
                open: function () {
                    Triggers.modalOpen(this);
                    Tooltip.closeAll();
                },

                afterClose: function () {
                    Triggers.modalClose(this);
                },
                parseAjax: function (response) {
                    Triggers.modalAjaxLoaded(this);
                }
            },
            ajax: {
                settings: {
                    dataType: 'html',
                }
            }
        });
        $('.modal-inline', parent).magnificPopup({
            showCloseBtn: false,
            fixedBgPos: true,
            fixedContentPos: true,
            alignTop: ! Resizer.isDesktop,
            modal: true,
            prependTo: '.modal-container',
            callbacks: {
                elementParse: function () {
                    this.st.focus = this.st.el.data('mfp-focus') ? this.st.el.data('mfp-focus') : false;
                },
                beforeOpen: function (item) {
                    this.wrap.removeAttr('tabindex');
                    var elementData = $(this.st.el).data();
                    // show hidden modal
                    $(elementData.mfpSrc).show();
                    // add class if exists in data-mfp-main-class
                    if (elementData.mfpMainClass) {
                        this.st.mainClass = elementData.mfpMainClass;
                    }
                },
                open: function () {
                    Triggers.inputHandlers();
                    $('html').css({
                        'overflow': 'auto',
                        'margin-right': '0'
                    });
                    var elementData = $(this.st.el).data();
                    if ($(this.st.el).data('email')) {
                        $('#login-email').val($(this.st.el).data('email'));
                        $('#forgot-email').val($(this.st.el).data('email'));
                    }
                    $(elementData.mfpSrc).parent().height($(elementData.mfpSrc).height());
                },
                afterClose: function () {}
            }
        });
        /* Checkout modal-choice */
        $('#modal-trigger', parent).magnificPopup({
            showCloseBtn: false,
            alignTop: ! Resizer.isDesktop,
            modal: false,
            prependTo: '.modal-container',
            callbacks: {
                beforeOpen: function () {
                    this.wrap.removeAttr('tabindex');
                },
                open: function () {
                    var elementData = $(this.st.el).data();
                    Triggers.inputHandlers();
                    $('.show-after-modal').removeClass('hidden').show();
                    $(elementData.mfpSrc).parent().height($('.show-after-modal').height());
                    $('html').css({
                        'overflow': 'auto',
                        'margin-right': '0'
                    });
                },
                afterClose: function () {}
            },
            ajax: {
                settings: {
                    dataType: 'html'
                }
            }
        }).trigger('click');

        /* Image lightbox - wordpress*/
        $('.image-gallery', parent).each(function () {
            $(this).magnificPopup({
                showCloseBtn: false,
                type: 'image',
                tLoading: 'Loading image #%curr%...',
                mainClass: 'mdl-auto',
                delegate: '.grdcell > a, li > a',
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    preload: [0,1], // Will preload 0 - before current, and 1 after the current image
                    arrowMarkup: '<div title="%title%" class="mfp-arrow mfp-arrow-%dir%"></div>', // markup of an arrow button
                },
                fixedContentPos: false,
                image: {
                    titleSrc: function(item) {
                        var title,
                            img = item.el.find('img');

                        if (img.attr('title')) {
                            title = img.attr('title');
                        } else if (img.attr('alt')) {
                            title = img.attr('alt');
                        } else {
                            title = '&nbsp;';
                        }

                        return title;
                    },
                    tError: '<a href="%url%">Slike #%curr%</a> ni bilo mogoče naložiti.',
                    markup: '<div class="mdl mfp-figure">'+
                    '<div class="mdl-header">'+
                    '<div class="mdl-title">'+
                    '<h4 class="rubik mfp-title"></h4>'+
                    '<i class="icon-close mfp-close"></i>'+
                    '</div>'+
                    '</div>'+
                    '<div class="mdl-content mdl-image">'+
                    '<div class="img-holder"><div class="mfp-img"></div></div>'+
                    '</div>'+
                    '</div>',
                }
            });
        });


        /* Image lightbox - wordpress*/
        $('.image-holder', parent).magnificPopup({
            showCloseBtn: false,
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mdl-auto',
            gallery: {
                enabled: false,
                navigateByImgClick: true,
                preload: [0,1], // Will preload 0 - before current, and 1 after the current image
                arrowMarkup: '<div title="%title%" class="mfp-arrow mfp-arrow-%dir%"></div>', // markup of an arrow button
            },

            image: {
                titleSrc: function(item) {
                    var title,
                        img = item.el.find('img');

                    if (img.attr('title')) {
                        title = img.attr('title');
                    } else if (img.attr('alt')) {
                        title = img.attr('alt');
                    } else {
                        title = '&nbsp;';
                    }

                    return title;
                },
                tError: '<a href="%url%">Slike #%curr%</a> ni bilo mogoče naložiti.',
                markup: '<div class="mdl mfp-figure">'+
                            '<div class="mdl-header">'+
                                '<div class="mdl-title">'+
                                    '<h4 class="rubik mfp-title"></h4>'+
                                    '<i class="icon-close mfp-close"></i>'+
                                '</div>'+
                            '</div>'+
                            '<div class="mdl-content mdl-image">'+
                                '<div class="img-holder"><div class="mfp-img"></div></div>'+
                            '</div>'+
                        '</div>',
            },
        });

        /* Social share - custom iframe window */
        $('.soc-square a').on('click', function(e) {
            e.preventDefault();
            var height = 300,
                width = 500,
                data = null,
                $this;

            $this = $(this);
            data = $this.data();

            if (typeof data.width !== 'undefined') {
                width = data.width;
            }
            if (typeof data.height !== 'undefined') {
                height = data.height;
            }

            Utility.windowPopup($this.attr('href'), width, height);
        });

    }
};
