var Customer = {
    init: function() {
        // Register
        $('.js-customer-create:not(.btn-loading)').on('click', Customer.store);

        // Find company by vatid
        $('.js-findcompany').on('keyup', Customer.findCompany);

        // Find city by post number
        $('.js-findpost').on('keyup', Customer.findPost);

        // Login
        $(document.body).on('submit', '.js-login-form', Customer.login);

        // Logout
        $(document.body).on('click', '.js-customer-logout', Customer.logout);

        // Password reset
        $(document.body).on('submit', '.js-customer-pwdreset', Customer.resetPassword);
    },

    token: function() {
        if (typeof Cookies == "undefined") {
            return false;
        }
        var token = Cookies.get('token');
        return (typeof token !== 'undefined') ? token : false;
    },

    id: function() {
        if (typeof CID !== 'undefined') {
            if(CID) {
                return CID;
            }
        }
        return false;
    },

    isLoggedin: function(callback) {
        if (!this.token()) {
            return callback.call(false);
        }
        $.ajax({
            url: Hlp.getApi() + '/customer',
            success: function(data) {
                callback.call(true);
            },
            error : function(xhr) {
                callback.call(false);
            }
        });
    },

    login: function(event) {
        event.preventDefault();
        var form  = $(this);
        var email = $('[name="email"]',form).val();
        var password = $('[name="password"]',form).val();

        if(email === '' || password === '') {
            return;
        }

        //Tracking.track({ eventCategory: 'Customer', eventAction: 'Login', eventLabel: email});
        $.ajax({
            url: window.location.origin + '/login',
            type: 'POST',
            data: {
                'email'    : email,
                'password' : password
            },
            success: function(response) {
                if (response && response.success) {
                    Cookies.set('token', response.data.access_token, {
                        domain: '.' + window.location.hostname,
                        expires: new Date(response.data.expires_at * 1000)
                    });
                    $('.notice', form).hide();
                    $('.input-group', form).removeClass('notice-error').addClass('notice-success');
                    location.reload();
                } else {
                    $('.notice', form).fadeIn();
                    $('.input-group', form).addClass('notice-error').removeClass('notice-success');
                }
            },
            error : function(xhr) {
                $('.notice', form).fadeIn();
                $('.input-group', form).addClass('notice-error').removeClass('notice-success');
            }
        });
    },

    logout: function (event) {
        event.preventDefault();
        var accessToken = Customer.token();
        //Tracking.track({ eventCategory: 'Customer', eventAction: 'Logout', eventLabel: accessToken});

        $.ajax({
            url: Hlp.getApi() + '/logout',
            type: 'POST',
            data: {'access_token' : accessToken},
            dataType : 'text',
            success: function(data) {
                Cookies.remove('token');
                try {
                    localStorage.clear(); // sisyphus data
                } catch(e) {
                }

                //odstrani podkontakt iz sessiona
                $.get('/contact-remove', {}, function(){
                    location.reload();
                });

            },
            error : function(xhr) {
                Cookies.remove('token');
                location.reload();
            }
        });
    },

    resetPassword : function(event) {
        event.preventDefault();
        var form = $(this),
            email = $('[name="email"]',form).val();
        $.ajax({
            url: Hlp.getApi() + '/customer/password-reset',
            type: 'POST',
            data: {
                'email'      : email,
            },
            success: function(data) {
                $('.notice', form).fadeOut();
                $('.notice-too-many-requests', form).fadeOut();
                $('.success', form).fadeIn();
                $('.input-group', form).removeClass('notice-error').addClass('notice-success');
            },
            error : function(xhr) {
                if (xhr.status == 404) {
                    $('.notice', form).fadeOut();
                    $('.notice-too-many-requests', form).fadeOut();
                    $('.success', form).fadeIn();
                    $('.input-group', form).removeClass('notice-error').addClass('notice-success');
                } else {
                    if (xhr.status == 429) {
                        $('.notice-too-many-requests', form).fadeIn();
                        $('.notice', form).fadeOut();
                    } else {
                        $('.notice', form).fadeIn();
                        $('.notice-too-many-requests', form).fadeOut();
                    }
                    $('.success', form).fadeOut();
                    $('.input-group', form).removeClass('notice-success').addClass('notice-error');
                }
            }
        });
        //Tracking.track({ eventCategory: 'Customer', eventAction: 'PasswordReset', eventLabel: email});
    },

    exists: function(event) {
        var input = $(this);
        var group = input.closest('.input-group');

        $.ajax({
            url: Hlp.getApi() + '/customer/exists/' + input.val(),
            success: function(data) {

                $('a[data-mfp-src="#modal-login"]').data('email', input.val());
                group.addClass('notice-error');
                group.find('.notice').fadeOut();
                group.find('.notice[data-error="email_exists"]').hide().removeClass('hidden').fadeIn().focus();

            },
            error : function(xhr) {

                if(xhr.status === 404) {
                    group.removeClass('notice-error');
                    group.find('.notice').fadeOut();
                }

                if(xhr.status === 422) {
                    group.addClass('notice-error');
                    group.find('.notice').fadeOut();
                    group.find('.notice[data-error="invalid_email"]').hide().removeClass('hidden').fadeIn().focus();
                }

            }
        });
    },

    /**
     * Get company info using VAT ID
     * Used in order and affiliate form
     * @return {object}
     */
    findCompany: function(event) {

        var input = $(this);
        var vatid = input.val().replace(' ', '');

        // Ne izvajaj AJAX klicev, če je vpisana davčna krajša od 7 znakov
        if (vatid.length < 8) {
            return false;
        }

        $.ajax({
            url: Hlp.getApi() + '/company/' + vatid,
            success: function(data) {
                var fields = {
                    '#company, .company-id' : 'title',
                    '#address, .address-id' : 'address',
                    '#post, .post-id' : 'zip',
                    '#city, .city-id': 'city'
                };

                $.each(fields, function(index, dataName) {
                    var $target = $(index);

                    $target.each(function(index, val) {
                        var $val = $(val);
                        if ($val.is('input')) {
                            $val.val(data[dataName]);
                        } else {
                            $val.text(data[dataName]);
                        }
                    });
                });

                if(data.vat == 1) {
                    $('#ddv').attr('checked', true).siblings('label').addClass('checked');
                } else {
                    $('#ddv').attr('checked', false).siblings('label').removeClass('checked');
                }
            },
            error : function(data) {

            }
        });
    },

    findPost: function(event) {
        var input = $(this),
            zip = input.val().replace(' ', ''),
            parent  = input.parents('#oseba, #podjetje');

        // V primeru, da gre za delno poštno številko, ne izvedi ajax klica
        if (zip.length < 4) {
            return false;
        }

        $.ajax({
            url: Hlp.getApi() + '/post/' + zip,
            success: function(data) {
                $('.js-findpost-city', parent).val(data.title);
            },
            error : function(data) {
                $('.js-findpost-city', parent).val('');
            }
        });
    },

    store: function(event) {
        event.preventDefault();

        var $customerCreate = $('.js-customer-create');

        // Customer is already beeing created
        if($customerCreate.hasClass('btn-loading')) {
            return false;
        }

        Customer.create(function(){
            var customer_created = this;
            if(customer_created) {
                $customerCreate.removeClass('btn-loading');
                //localStorage.clear(); // sisyphus data - if customer logs out
                location.reload();
            } else {
                $customerCreate.removeClass('btn-loading');
            }
        });
    },

    create: function(callback) {
        var form = $('#register-form');
        var data = form.serialize();
        var holder, html;

        var noticeClean = function(form) {
            $('.notice-text:not(.static)', form).remove();
            $('.notice-error', form).removeClass('notice-error');
            $('.notice', form).fadeOut();
        };

        $.ajax({
            url: window.location.origin + '/signup',
            type: 'POST',
            data: data,
            success: function(data) {
                if (data && data.success) {
                    noticeClean();

                    var cookieOptions = {
                        domain: '.' + window.location.hostname,
                        expires: new Date(data.expires_at * 1000)
                    };

                    if (data.remember_me) {
                        cookieOptions.rememberme = 'yes';
                    }

                    Cookies.set('token', data.auth.accessToken, cookieOptions);
                    callback.call(true);
                    //Tracking.track({eventCategory: 'Customer', eventAction: 'Create', eventLabel: 'success'});
                } else {
                    noticeClean();
                    $('.notice[data-error="signup_error"]', form).hide().removeClass('hidden').addClass('notice-error').fadeIn();
                    //Tracking.track({ eventCategory: 'Customer', eventAction: 'Create', eventLabel: 'fail'});
                    callback.call(false);
                }
            },
            error : function(xhr) {
                //reset first
                noticeClean();

                var err = xhr.responseJSON;
                var focusChanged = false;

                for (var key in err) {

                    holder = $('.notice[data-key="' + key + '"]', form);
                    for (var i in err[key]) {

                        //custom error for login advice
                        if(err[key][i] === '##customerexists##') {
                            $('.notice[data-error="email_exists"]', form).hide().removeClass('hidden').addClass('notice-error').fadeIn();
                        } else {

                            html = $('<strong class="notice-text"><div class="error">' + err[key][i] + '</div></strong>');
                            holder.append(html).hide().removeClass('hidden').fadeIn();
                            if (focusChanged === false) {
                                holder.parent().addClass('notice-error').velocity('scroll', { duration: 500, offset: -90, easing: 'linear' });
                                focusChanged = true;
                            } else {
                                holder.parent().addClass('notice-error');
                            }
                        }
                    }
                }
                //Tracking.track({ eventCategory: 'Customer', eventAction: 'Create', eventLabel: 'fail'});
                callback.call(false);
            }
        });
    },


};
