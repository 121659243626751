/*===============================
=            Package carousel   =
===============================*/

var PackageCarousel = {

    init: function () {

        if($('.js-packagelist .packages').length) {

            var slider = tns({
                container: '.js-packagelist .packages',
                items: 1,
                slideBy: 1,
                edgePadding: 0,
                autoplay: false,
                controls: true,
                loop: false,
                mouseDrag: true,
                center: true,
                controlsContainer: '.tns-controls',
                nav: false,
                startIndex: 2,
                fixedWidth: 300,
                gutter:30,
                responsive: {
                    900: {
                        items: 2,
                        gutter:30,
                    },
                    1220: {
                        center: true,
                        items: 3,
                        gutter:30,
                    }
                },
                onInit: function(sld){
                    PackageCarousel.handleTabs(sld);
                }
            });

            $(document).on('click', '.js-packagelist .tabs a', function() {
                $(this).parent().siblings().find('a').removeClass('active');
                $(this).addClass('active');
                var i = $(this).parent().index();
                slider.goTo(i);
            });

            slider.events.on('indexChanged', function(sld){
                PackageCarousel.handleTabs(sld);
            });
        }

        $('.package-list2').css({opacity: 0.0, visibility: "visible"}).animate({opacity: 1.0});

    },

    handleTabs: function (sld) {
        var tabs = $(sld.container).parents('.js-packagelist').find('.tabs li');
        tabs.find('a').removeClass('active');
        $(tabs.get(sld.index)).find('a').addClass('active');
        //first
        if(sld.index == 0) {
            $(sld.controlsContainer).find('.prev').fadeOut();
        } else {
            $(sld.controlsContainer).find('.prev').show();
        }
        if(sld.index >= (tabs.get().length - 1)) {
            $(sld.controlsContainer).find('.next').fadeOut();
        } else {
            $(sld.controlsContainer).find('.next').show();
        }
    }
};
